import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CurrencyRatesProvider extends HttpRequest {
  getCurrencyRates (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/currency-rates', query)
  }

  createCurrencyRate (payload) {
    this.setHeader(getAuthToken())
    return this.post('/currency-rates', payload)
  }

  updateCurrencyRate (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/currency-rates/${id}`, payload)
  }

  deleteCurrencyRate (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/currency-rates/${id}`)
  }
}

export default CurrencyRatesProvider
