<template>
  <v-container class="form-container rounded elevation-3">
    <v-form
      ref="form"
      @submit.prevent="confirmAttribute()">
      <v-row>
        <v-col
          cols="6"
          style="font-size: 24px;">
          Currency Rates
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn
            :loading="loading"
            :disabled="!isEdit"
            color="success"
            @click="confirmAttribute()">
            Save
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model.number="formData.foriegnCurrency.value"
            type="number"
            :rules="textFieldRules"
            :readonly="loading"
            :label="formData.foriegnCurrency.label.toUpperCase()"
            dense
            filled
            color="secondary"
            hide-details
            :disabled="!!formData.foriegnCurrency.value" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model.number="formData.thb"
            type="number"
            :rules="textFieldRules"
            :readonly="loading"
            label="THB"
            dense
            filled
            color="secondary"
            hide-details />
        </v-col>
        <!-- <v-col
          cols="12"
          md="6">
          <DatePicker
            v-model="formData.startDate"
            label="Start Date"
            filled
            clearable
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <DatePicker
            v-model="formData.endDate"
            label="End Date"
            filled
            clearable
            :disabled="loading" />
        </v-col> -->
        <v-col cols="12">
          อัพเดทล่าสุด : {{ formData.createdAt | dateTimeUserFormat() }} น.
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// import DatePicker from '@/components/DatePicker.vue'
import CurrencyRatesProvider from '@/resources/CurrencyRatesProvider'
import { mapActions } from 'vuex'

const CurrencyRatesService = new CurrencyRatesProvider()

export default {
  // components: {
  //   DatePicker
  // },
  data () {
    return {
      loading: false,
      formData: {
        thb: null,
        foriegnCurrency: {
          isoCode: 'usd',
          label: 'usd',
          value: 1
        },
        startDate: null,
        endDate: null
      },
      memoFormData: {
        thb: null,
        foriegnCurrency: {
          isoCode: 'usd',
          label: 'usd',
          value: 1
        },
        startDate: null,
        endDate: null
      },
      textFieldRules: [
        (v) => /^[+-]?([0-9]*[.])?[0-9]+$/.test(v) || 'This field is invalid.'
      ]
    }
  },
  computed: {
    isEdit () {
      return JSON.stringify(this.formData) !== JSON.stringify(this.memoFormData)
    }
  },
  mounted () {
    this.getCurrencies()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getCurrencies () {
      try {
        this.loading = true

        const { data } = await CurrencyRatesService.getCurrencyRates({
          page: 1
        })

        this.formData = JSON.parse(JSON.stringify(data.results[0]))
        this.memoFormData = JSON.parse(JSON.stringify(data.results[0]))
      } catch (error) {
        console.error('getCurrencies', error.message)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async createCurrency () {
      try {
        this.loading = true

        const valid = await this.$refs.form.validate()

        if (valid) {
          await CurrencyRatesService.createCurrencyRate(this.formData)

          this.setSnackbar({
            value: true,
            message: 'Create currency rate success.',
            type: 'success'
          })

          this.getCurrencies()
        }
      } catch (error) {
        console.error('createCurrency', error.message)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async confirmAttribute () {
      const valid = await this.$refs.form.validate()

      if (!valid) {
        return
      }

      this.setModal({
        value: true,
        title: 'UpdateCurrencyRate',
        message: 'Do you want to update the CurrencyRate?',
        confirmText: 'Confirm',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.createCurrency()
      })
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  height: fit-content;
}
</style>
